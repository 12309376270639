<template>
  <div>
    <!-- This is for mobile side -->
    <div class="d-md-none">
      <MobileLandingVue />
    </div>
    <!-- This is for desktop side -->
    <div class="d-none d-md-block">
      <b-navbar sticky>
        <b-navbar-brand href="#"
          ><img
            src="@/assets/images/logo/logo-red.png"
            alt=""
            contain
            height="45px"
            width="65px"
        /></b-navbar-brand>
        <b-nav tabs fill class="ml-auto mt-1">
          <b-nav-item href="https://abudhabi.golog.my/"
            ><FeatherIcon icon="MapIcon" size="25" /> AIM Congress
            2024</b-nav-item
          >
          <b-button variant="primary" class="btn-sm" v-b-modal.modal-2>
            <FeatherIcon icon="NavigationIcon" size="15" />
            Login
          </b-button>
        </b-nav>
      </b-navbar>
      <div class="pb-3">
        <div class="d-flex">
          <img
            ref="mainpicture1"
            class="fade-in-from-bottom"
            src="@/assets/images/carousel/carousel1.png"
            alt="Image"
            style="
              object-fit: cover;
              object-position: bottom;
              width: 100%;
              height: 70vh;
            "
          />
          <img
            ref="mainpicture2"
            class="fade-in-from-right"
            src="@/assets/images/carousel/coldtruck.png"
            alt=""
            style="
              object-fit: fill;
              object-position: center;
              width: 40%;
              height: 70vh;
            "
          />
        </div>
        <div class="cardColor py-4">
          <div
            class="d-flex align-items-center justify-content-center mx-auto w-75"
          >
            <b-row>
              <b-col cols="1">
                <div class="d-flex justify-content-left my-2 mr-2">
                  <img
                    src="@/assets/images/logo/logo-red.png"
                    alt=""
                    contain
                    height="85px"
                    width="100px"
                  /></div
              ></b-col>
              <b-col cols="8"
                ><div>
                  <div class="fade-in-from-left" ref="titlelogo">
                    <!-- <h5 class="text-primary mx-3" style="text-align: justify">
                    “Frozen food market in Malaysia is forecast to grow at a
                    compounded annual growth rate (CAGR) of more than 7% over
                    five years, a report said. By the end of 2023, the market
                    size would be about USD $800mil.” by MIDA
                  </h5> -->
                  </div>
                  <div class="fade-in-from-right" ref="titletext">
                    <h4 class="mb-1" style="text-align: justify">
                      <span>
                        <b-icon icon="check2-circle" variant="primary"></b-icon>
                      </span>
                      An intelligent cold chain logistic platform specially
                      design for B2B business were using technology and
                      infrastructure to support suppliers purchase order
                      delivery via sharing economic concept by linking up
                      majority cold chain logistics company cold trucks.
                    </h4>
                    <h4 style="text-align: justify">
                      <span>
                        <b-icon icon="check2-circle" variant="primary"></b-icon>
                      </span>
                      Malaysia's very first cold chain logistics technology
                      company that enable loose carton delivery for all B2B
                      suppliers and fulfill orders with empty cold trucks on
                      road to achieve high efficiency, hassle free and lower
                      cost.
                    </h4>
                  </div>
                </div></b-col
              >
              <b-col cols="3"
                ><div
                  class="d-flex align-items-center justify-content-center fade-in-from-left mt-2"
                  ref="createorder"
                >
                  <b-button pill v-b-modal.modal-1 variant="primary"
                    ><h4 class="text-white my-1">
                      CREATE YOUR ORDER NOW!
                    </h4></b-button
                  >
                </div></b-col
              >
            </b-row>
          </div>
        </div>
      </div>

      <div class="p-5 mx-5 rcorners3 mt-5">
        <div>
          <div class="d-flex align-items-center justify-content-center mb-3">
            <h1
              class="text-primary mx-3 customText"
              style="text-align: justify"
            >
              <strong>Problem Statement</strong>
            </h1>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div>
              <b-row class="mb-2">
                <b-col>
                  <div
                    class="d-flex align-items-center justify-content-center fade-in-from-bottom2022"
                    ref="problem"
                  >
                    <h3
                      class="text-black mx-3 customText"
                      style="text-align: justify"
                    >
                      <strong>
                        <span>
                          <b-icon
                            icon="arrow-down-square"
                            variant="primary"
                            class="mr-2"
                          ></b-icon> </span
                        >Low Goods Volume</strong
                      >
                    </h3>
                  </div>
                </b-col>
                <b-col>
                  <div
                    class="d-flex align-items-center justify-content-center fade-in-from-bottom2023"
                    ref="problem2"
                  >
                    <h3
                      class="text-black mx-3 customText"
                      style="text-align: justify"
                    >
                      <strong>
                        <span>
                          <b-icon
                            icon="thermometer"
                            variant="primary"
                            class="mr-2"
                          ></b-icon> </span
                        >Delivery Goods Lifespan</strong
                      >
                    </h3>
                  </div>
                </b-col>
                <b-col>
                  <div
                    class="d-flex align-items-center justify-content-center fade-in-from-bottom2024"
                    ref="problem3"
                  >
                    <h3
                      class="text-black mx-3 customText"
                      style="text-align: justify"
                    >
                      <strong>
                        <span>
                          <b-icon
                            icon="cash"
                            variant="primary"
                            class="mr-2"
                          ></b-icon> </span
                        >Delivery Cost</strong
                      >
                    </h3>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div ref="problem4" class="fade-in-from-bottom2022">
                    <b-card
                      class="h-100 d-flex align-items-center justify-content-center rcorners2"
                    >
                      <h4 style="text-align: justify" class="pt-2">
                        90% of frozen goods are in B2B segment, and loose
                        cartons represent the biggest pain point in the market,
                        posing a challenge for suppliers to deliver frozen
                        goods.
                      </h4>
                    </b-card>
                  </div></b-col
                >
                <b-col
                  ><b-card
                    class="h-100 d-flex align-items-center justify-content-center rcorners2 fade-in-from-bottom2023"
                    ref="problem5"
                    style="text-align: justify"
                  >
                    <h4 style="text-align: justify" class="pt-2">
                      The short expiry date of purchase orders for loose cartons
                      and the need to deliver to multiple different places in
                      various states present challenges.
                    </h4>
                  </b-card></b-col
                >
                <b-col
                  ><b-card
                    class="h-100 d-flex align-items-center justify-content-center rcorners2 fade-in-from-bottom2024"
                    ref="problem6"
                    style="text-align: justify"
                  >
                    <h4 style="text-align: justify" class="pt-2">
                      There is a high cost associated with not fully loaded
                      trucks or the outsourcing logistics.
                    </h4>
                  </b-card></b-col
                >
              </b-row>
            </div>
          </div>
        </div>
      </div>

      <div class="p-5 mt-3">
        <div>
          <div class="d-flex align-items-center justify-content-center my-3">
            <h1
              class="text-primary mx-3 customText"
              style="text-align: justify"
            >
              <strong>Our Solution</strong>
            </h1>
          </div>
          <div id="smarttruck">
            <b-card class="mx-5 mb-5 rcorners2 h-100" style="height: 350px">
              <div class="d-flex align-items-center justify-content-center">
                <div>
                  <h1 class="text-primary mx-3 customText" style="text-align: justify">
                    <strong>GOLOG Cold Chain Journey</strong>
                  </h1>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <img src="@/assets/images/carousel/solution.png" alt="" class="w-75" />
              </div>
              <b-row class="mt-5">
                <b-col>
                  <b-button v-b-toggle.collapse-1 variant="white" v-b-tooltip.hover title="Click to learn more!">
                    <img src="@/assets/images/carousel/b2bprologo.png" alt="" class="w-75" />
                  </b-button>
                  <b-collapse id="collapse-1" class="mt-2">
                    <b-card>
                      <div class="card-text">
                        <img src="@/assets/images/carousel/b2bpro.png" alt="" class="w-100" />
                        <h4 class="d-flex align-items-center justify-content-center mt-3" style="text-align: justify">
                          <a href="https://b2bpro.golog.my/login">
                            <FeatherIcon icon="PackageIcon" size="25" />Visit B2B Pro Now!
                          </a>
                        </h4>
                      </div>
                    </b-card>
                  </b-collapse>
                </b-col>
                <b-col>
                  <b-button v-b-toggle.collapse-2 variant="white" v-b-tooltip.hover title="Click to learn more!">
                    <img src="@/assets/images/carousel/smarttrucklogo.png" alt="" class="w-75" />
                  </b-button>
                  <b-collapse id="collapse-2" class="mt-2">
                    <b-card>
                      <p class="card-text">
                        <img src="@/assets/images/carousel/smarttruck.png" alt="" class="w-100" />
                      </p>
                    </b-card>
                  </b-collapse>
                </b-col>
              </b-row>
              <div class="text-center mt-4">
                <h4>You can download our apps here:</h4>
                <b-link class="ml-25" href="https://play.google.com/store/apps/details?id=com.golog.driverapp" target="_blank">
                  <FeatherIcon icon="PlayIcon" size="15" />Driver App
                </b-link>
                <b-link class="mx-25" href="https://play.google.com/store/apps/details?id=com.golog.minidc_tracker_app" target="_blank">
                  <FeatherIcon icon="MoveIcon" size="15" />Tracker App
                </b-link>
              </div>
            </b-card>
          </div>
        </div>
      </div>
      <div class="p-5 cardColor mt-3">
        <div class="container">
          <div class="row">
            <div class="col">
              <img class="img-fluid" src="/1.jpg" alt="First image" />
            </div>
            <div class="col">
              <img class="img-fluid" src="/2.jpg" alt="Second image" />
            </div>
            <div class="col">
              <img class="img-fluid" src="/3.jpg" alt="Third image" />
            </div>
            <div class="col">
              <img class="img-fluid" src="/4.jpg" alt="Fourth image" />
            </div>
          </div>
        </div>
        <div>
          <b-card
            class="mt-3 mx-5 fade-in-from-bottom rcorners1"
            ref="customer"
          >
            <div class="d-flex justify-content-center">
              <h1
                class="text-primary mx-3 customText"
                style="text-align: justify"
              >
                <strong>Customer Portfolio</strong>
              </h1>
            </div>
            <div class="d-flex justify-content-center">
              <img
                src="@/assets/images/carousel/customerportfolio.png"
                alt=""
                class="w-75"
              />
            </div>
          </b-card>
        </div>
      </div>
      <!-- <div class="p-5 mt-3">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <h1 class="text-primary mx-3 customText">
                <strong>Our Achievements</strong>
              </h1>
            </div>
          </div>
        </div>
        <b-carousel id="carousel-1" :interval="3000" controls>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img src="@/assets/images/carousel/achievement1.png" />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="mx-3 customText mt-2">
                  <strong>Create@Alibaba Cloud Startup Contest</strong>
                </h4>
              </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img src="@/assets/images/carousel/achievement2.png" />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="mx-3 customText mt-2">
                  <strong>JCI SUSTAINABLE DEVELOPMENT AWARDS 2022</strong>
                </h4>
              </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img src="@/assets/images/carousel/achievement3.png" />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="mx-3 customText mt-2">
                  <strong>GOLOG X MYDIN MOU SIGNING COLLABORATION</strong>
                </h4>
              </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img src="@/assets/images/carousel/achievement4.png" />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="mx-3 customText mt-2">
                  <strong>MYDIN DELIVERY ON GOING </strong>
                </h4>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div> -->
      <div class="p-5 mt-3" id="outlets">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h1 class="text-primary mx-3 customText" style="text-align: justify">
            <strong>Our Outlets</strong>
          </h1>
        </div>
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/hq.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Head Quarters</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >26, Jalan Industri Mas 14, Taman Mas, 47100 Puchong,
                Selangor</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>017-288 1735</b-col>
            </b-row>
          </b-col>
          <b-col
            ><div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/seremban.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Seremban</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >169G, Jalan S2B3, Seremban 2, 70300 Seremban, Negeri
                Sembilan</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>010-342 7461</b-col>
            </b-row></b-col
          >
          <b-col
            ><div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/jb_360.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Johor Bahru</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >59, Jalan Ros Merah 2/2, Taman Johor Jaya, 81100 Johor Bahru,
                Johor</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>016-742 7666</b-col>
            </b-row></b-col
          >
        </b-row>
        <b-row class="my-2 py-2 cardColor rcorners1">
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/melaka.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Melaka</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >6, JALAN IAN 3, Jln Industri Angkasa Nuri, 76100 ,
                Melaka</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>011-3534 4593</b-col>
            </b-row>
          </b-col>
          <b-col
            ><div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/bukit_mertajam.png"
                style="
                  object-fit: contain;
                  object-position: center;
                  width: 29%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Bukit Mertajam</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >9, Jalan Limau Manis 4, Taman Limau Manis, 14000 Bukit
                Mertajam, Pulau Pinang</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>012-439 2996</b-col>
            </b-row></b-col
          >
          <b-col
            ><div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/taiping.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Taiping</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >No.9, Tingkat Bawah Jalan Susur Simpang, Off Jalan Simpang,
                Taman Perusahaan Ringan Simpang, 34000 Taiping, Perak</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>016-551 1133</b-col>
            </b-row></b-col
          >
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/terengganu.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Kuala Terengganu</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >85-F (Tingkat Bawah), Jalan Sultan Sulaiman, 20000 Kuala
                Terengganu, Terengganu</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>016-959 0060</b-col>
            </b-row>
          </b-col>
          <b-col
            ><div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/ipoh.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 29%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Ipoh</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >No. 26 Bercham Bistari 1, Medan Bercham Bistari, 31400 Iphoh,
                Perak</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>012-581 1285</b-col>
            </b-row></b-col
          >
          <b-col
            ><div class="d-flex align-items-center justify-content-center">
              <img
                src="@/assets/images/carousel/klang.png"
                style="
                  object-fit: fill;
                  object-position: center;
                  width: 50%;
                  height: 50%;
                "
              />
            </div>
            <h5
              class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
            >
              <strong>Klang</strong>
            </h5>
            <b-row
              class="d-flex align-items-center justify-content-center my-1"
            >
              <b-col cols="1"
                ><FeatherIcon icon="MapPinIcon" size="25"
              /></b-col>
              <b-col
                >No.7-G, Jalan Kasuarina 4/KS7, Bandar Botanic, 4100 Klang,
                Selangor D.E.</b-col
              >
            </b-row>
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
              <b-col>011-6272 7662</b-col>
            </b-row></b-col
          >
        </b-row>
      </div>

      <b-modal id="modal-1" title="BootstrapVue" hide-header hide-footer>
        <b-row class="my-2">
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="BoxIcon" size="75" />
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="TruckIcon" size="75" />
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <b-button
                variant="primary"
                class="btn-sm-block"
                @click="redirectToExternalSite"
              >
                Outlet Login
              </b-button>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <b-button
                variant="primary"
                class="btn-sm-block"
                @click="redirectToExternalB2c"
              >
                Distributor B2C Login
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-modal>

      <footer class="cardColor px-3">
        <span class="clearfix mb-0">
          <span
            class="float-md-left d-inline-block d-md-inline-block align-middle"
          >
            <img
              :src="require('@/assets/images/logo/logo-red.png')"
              style="height: 26px"
              class="my-1"
            />
            © {{ new Date().getFullYear() }}
            <b-link class="ml-25" href="https://golog.my/" target="_blank"
              >GOBUILDERS NETSOFT SDN BHD (1232479-T).
            </b-link>
            <span class="d-none d-sm-inline-block">ALL RIGHTS RESERVED.</span>
          </span>
          <span class="float-md-right d-none d-md-block">
            <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true', 'Refund Policy')" class="ml-25 text-primary">
              Refund Policy
            </b-link>
            |
            <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true', 'Data Privacy Policy')" class="ml-25 text-primary">
              Data Privacy Policy
            </b-link>
            |
            <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true', 'Rules & Conduct Policy')" class="ml-25 text-primary">
              Rules & Conduct Policy
            </b-link>
            |
            <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true', 'Privacy Terms Policy')" class="ml-25 text-primary">
              Privacy Terms Policy
            </b-link>
            |
            <b-link class="ml-25 text-primary" href="#">
              (CONTACT US: 0172881735)
            </b-link>
            <img
              :src="require('@/assets/images/logo/visa.png')"
              style="height: 52px"
            />
          </span>
        </span>
      </footer>
    </div>

    <b-modal v-model="showModal" :title="modalTitle" size="lg" hide-footer>
      <iframe
        :src="docUrl"
        width="100%"
        height="750px"
        frameborder="0"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
import MobileLandingVue from "./MobileLanding.vue";
export default {
  data() {
    return {
      showPrivacyModal: false,
      showModal: false,
      docUrl: '',
      modalTitle: '', 
    };
  },
  components: {
    MobileLandingVue,
  },
  created() {
    if (this.$route.path == "/privacy") {
      this.openPrivacyModal();
    }
  },
  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.2,
    };

    const mainpicture1 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.mainpicture1.classList.add("show");
        mainpicture1.unobserve(entries[0].target);
      }
    }, options);
    mainpicture1.observe(this.$refs.mainpicture1);
    const mainpicture2 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.mainpicture2.classList.add("show");
        mainpicture2.unobserve(entries[0].target);
      }
    }, options);
    mainpicture2.observe(this.$refs.mainpicture2);

    const titlelogo = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.titlelogo.classList.add("show");
        titlelogo.unobserve(entries[0].target);
      }
    }, options);
    titlelogo.observe(this.$refs.titlelogo);

    const titletext = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.titletext.classList.add("show");
        titletext.unobserve(entries[0].target);
      }
    }, options);
    titletext.observe(this.$refs.titletext);

    const createorder = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.createorder.classList.add("show");
        createorder.unobserve(entries[0].target);
      }
    }, options);
    createorder.observe(this.$refs.createorder);

    const problem = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem.classList.add("show");
        problem.unobserve(entries[0].target);
      }
    }, options);
    problem.observe(this.$refs.problem);
    const problem2 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem2.classList.add("show");
        problem2.unobserve(entries[0].target);
      }
    }, options);
    problem2.observe(this.$refs.problem2);
    const problem3 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem3.classList.add("show");
        problem3.unobserve(entries[0].target);
      }
    }, options);
    problem3.observe(this.$refs.problem3);
    const problem4 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem4.classList.add("show");
        problem4.unobserve(entries[0].target);
      }
    }, options);
    problem4.observe(this.$refs.problem4);
    const problem5 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem5.classList.add("show");
        problem5.unobserve(entries[0].target);
      }
    }, options);
    problem5.observe(this.$refs.problem5);
    const problem6 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem6.classList.add("show");
        problem6.unobserve(entries[0].target);
      }
    }, options);
    problem6.observe(this.$refs.problem6);

    const customer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.customer.classList.add("show");
        customer.unobserve(entries[0].target);
      }
    }, options);
    customer.observe(this.$refs.customer);
  },
  methods: {
    openModal(url, title) {
      this.docUrl = url;
      this.modalTitle = title;
      this.showModal = true;
    },
    redirectToExternalSite() {
      window.location.href = "https://minidc.golog.my";
    },
    redirectToExternalB2c() {
      window.location.href = "https://minidcdistributor.golog.my";
    },
    openPrivacyModal() {
      // Add /privacy to the URL
      this.$router.push("/privacy");
      this.showPrivacyModal = true;
    },
    closePrivacyModal() {
      // Remove /privacy from the URL
      this.$router.replace("/");
    },
  },
};
</script>

<style>
.azalea {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.911);
}

.customText {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.cardColor {
  background-color: rgba(236, 234, 234, 0.911);
}

.rcorners1 {
  border-radius: 90px;
}

.rcorners2 {
  border-radius: 15px 50px;
}

.rcorners3 {
  border-radius: 150px;
}

.bordertop {
  border-top: 5px solid rgb(235, 36, 75);
}

.borderbottom {
  border-bottom: 5px solid rgb(235, 36, 75);
}

.borderright {
  border-right: 5px solid rgb(235, 36, 75);
}

.borderleft {
  border-left: 5px solid #db1d6c;
  margin-left: 40px;
}

.borderx {
  border-left: 5px solid #db1d6c;
  border-right: 5px solid #db1d6c;
}

.achieveborder {
  border: 4px solid #db1d6c;
}

.animated-link {
  text-decoration: none;
  color: rgb(235, 36, 75);
  position: relative;
  transition: all 0.3s ease-in-out;
}

.animated-link::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #db1d6c;
  transition: all 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.animated-link:hover::before {
  transform: scaleX(1);
}

.fade-in-from-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-right {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-from-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-left {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-from-bottom {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-bottom {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-pop {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1.5s ease-in,
    transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.show.fade-in-pop {
  opacity: 1;
  transform: scale(1);
}

.fade-in-from-bottom2022 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-bottom2022 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottom2023 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s ease-in, transform 2s ease-in;
}

.show.fade-in-from-bottom2023 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottom2024 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2.5s ease-in, transform 2.5s ease-in;
}

.show.fade-in-from-bottom2024 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottom2025 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 3s ease-in, transform 3s ease-in;
}

.show.fade-in-from-bottom2025 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottomprogress {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 3s ease-in, transform 3s ease-in;
}

.show.fade-in-from-bottomprogress {
  opacity: 1;
  transform: translateY(0);
}

.modal {
  top: 0%;
}
</style>

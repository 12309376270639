<template>
  <div style="overflow-x: hidden">
    <div class="d-md-none">
      <b-navbar toggleable variant="white">
        <b-navbar-brand href="#"
          ><img
            src="@/assets/images/logo/logo-red.png"
            alt=""
            contain
            height="25px"
            width="40px"
        /></b-navbar-brand>
        <div style="font-size: 12px; left: 0">
          <div>
            <b-icon icon="telephone" variant="primary"></b-icon>
            <span> 017-288 1735 </span>
          </div>
          <b-icon icon="envelope" variant="primary"></b-icon>
          <span> support@glogc.com</span>
        </div>
        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon
              v-if="expanded"
              icon="chevron-up"
              variant="primary"
            ></b-icon>
            <b-icon v-else icon="chevron-down" variant="primary"></b-icon>
          </template>
        </b-navbar-toggle>
        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto mt-2">
            <b-nav-item href="https://abudhabi.golog.my/"
              ><FeatherIcon icon="MapIcon" size="25" /> AIM Congress
              2024</b-nav-item
            >
            <b-button variant="primary" class="btn-sm" v-b-modal.modal-2>
              <FeatherIcon icon="NavigationIcon" size="15" />
              Login
            </b-button>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <div class="pb-3">
        <div class="container-fluid p-0" style="position: relative">
          <img
            src="@/assets/images/carousel/carousel1.png"
            alt="Image"
            style="object-fit: cover; object-position: center; width: 100%"
          />
          <div
            class="px-2 my-2 d-flex justify-content-between align-items-center"
          >
            <div>
              <b-card
                class="d-flex align-items-center justify-content-between mb-1 fade-in-from-left"
                ref="titlelogo"
              >
                <h6 class="text-primary" style="text-align: justify">
                  “Frozen food market in Malaysia is forecast to grow at a
                  compounded annual growth rate (CAGR) of more than 7% over five
                  years, a report said. By the end of 2023, the market size
                  would be about USD $800mil.” by MIDA
                </h6>
              </b-card>
              <div class="bordertopmobile">
                <div class="d-flex justify-content-center my-1">
                  <img
                    src="@/assets/images/logo/logo-red.png"
                    alt=""
                    contain
                    height="25px"
                    width="40px"
                  />
                </div>
                <div class="fade-in-from-right" ref="titletext">
                  <h5 class="mb-1" style="text-align: justify">
                    <span>
                      <b-icon icon="check2-circle" variant="primary"></b-icon>
                    </span>
                    An intelligent cold chain logistic platform specially design
                    for B2B business were using technology and infrastructure to
                    support suppliers purchase order delivery via sharing
                    economic concept by linking up majority cold chain logistics
                    company cold trucks.
                  </h5>
                </div>
                <div>
                  <h5
                    class="fade-in-from-left"
                    ref="titletext2"
                    style="text-align: justify"
                  >
                    <span>
                      <b-icon icon="check2-circle" variant="primary"></b-icon>
                    </span>
                    Malaysia's very first cold chain logistics technology
                    company that enable loose carton delivery for all B2B
                    suppliers and fulfill orders with empty cold trucks on road
                    to achieve high efficiency, hassle free and lower cost.
                  </h5>
                </div>
              </div>
              <div
                class="d-flex align-items-center justify-content-center mt-3 fade-in-from-right"
                ref="createorder"
              >
                <b-button pill v-b-modal.modal-1 variant="primary"
                  >CREATE YOUR ORDER NOW!</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-2 cardColor">
        <div>
          <h3
            class="text-primary customText d-flex justify-content-center"
            style="text-align: justify"
          >
            <strong>Problem Statement</strong>
          </h3>
        </div>
        <div class="d-flex align-items-center justify-content-center my-2">
          <div>
            <div class="fade-in-from-bottom1" ref="problem1">
              <div>
                <h5 class="text-black customText" style="text-align: justify">
                  <strong>
                    <span>
                      <b-icon
                        icon="arrow-down-square"
                        variant="primary"
                      ></b-icon>
                    </span>
                    Low Goods Volume</strong
                  >
                </h5>
              </div>
              <div>
                <b-card
                  class="h-100 d-flex align-items-center justify-content-center rcorners2"
                  style="height: 130px"
                >
                  <h6 style="text-align: justify">
                    90% of frozen goods are in B2B segment, and loose cartons
                    represent the biggest pain point in the market, posing a
                    challenge for suppliers to deliver frozen goods.
                  </h6>
                </b-card>
              </div>
            </div>
            <div class="fade-in-from-bottom2" ref="problem2">
              <div>
                <h5 class="text-black customText" style="text-align: justify">
                  <strong>
                    <span>
                      <b-icon icon="thermometer" variant="primary"></b-icon>
                    </span>
                    Delivery Goods Lifespan</strong
                  >
                </h5>
              </div>
              <div>
                <b-card
                  class="h-100 d-flex align-items-center justify-content-center rcorners2"
                  style="height: 130px"
                >
                  <h6 style="text-align: justify">
                    The short expiry date of purchase orders for loose cartons
                    and the need to deliver to multiple different places in
                    various states present challenges.
                  </h6>
                </b-card>
              </div>
            </div>
            <div class="fade-in-from-bottom3" ref="problem3">
              <div>
                <h5 class="text-black customText" style="text-align: justify">
                  <strong>
                    <span>
                      <b-icon icon="cash" variant="primary"></b-icon>
                    </span>
                    Delivery Cost</strong
                  >
                </h5>
              </div>
              <div>
                <b-card
                  class="d-flex align-items-center justify-content-center rcorners2"
                  style="height: 130px"
                >
                  <h6 style="text-align: justify">
                    There is a high cost associated with not fully loaded trucks
                    or the outsourcing logistics.
                  </h6>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="">
          <div class="d-flex align-items-center justify-content-center my-3">
            <h3
              class="text-primary mx-3 customText"
              style="text-align: justify"
            >
              <strong>Our Solution</strong>
            </h3>
          </div>
          <div id="smarttruck">
            <b-card class="mb-5 rcorners2 h-100" style="height: 350px">
              <div class="d-flex align-items-center justify-content-center">
                <div>
                  <h5
                    class="text-primary mx-3 customText"
                    style="text-align: justify"
                  >
                    <strong>GOLOG Cold Chain Journey</strong>
                  </h5>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/journey1.png"
                  alt=""
                  class="w-100"
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/journey2.png"
                  alt=""
                  class="w-100"
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/journey3.png"
                  alt=""
                  class="w-50"
                />
              </div>
              <div class="mt-5">
                <div>
                  <b-button v-b-toggle.collapse-1 variant="white">
                    <img
                      src="@/assets/images/carousel/b2bprologo.png"
                      alt=""
                      class="w-100"
                  /></b-button>
                  <b-collapse visible id="collapse-1" class="mt-2">
                    <div class="card-text">
                      <img
                        src="@/assets/images/carousel/b2bpro.png"
                        alt=""
                        class="w-100"
                      />
                      <h4
                        class="d-flex align-items-center justify-content-center mt-3"
                        style="text-align: justify"
                      >
                        <a href="https://b2bpro.golog.my/login"
                          ><FeatherIcon icon="PackageIcon" size="25" />Visit B2B
                          Pro Now!</a
                        >
                      </h4>
                    </div>
                  </b-collapse>
                </div>
              </div>
              <b-row class="mt-5">
                <b-col>
                  <b-button v-b-toggle.collapse-2 variant="white">
                    <img
                      src="@/assets/images/carousel/smarttrucklogo.png"
                      alt=""
                      class="w-100"
                  /></b-button>
                  <b-collapse visible id="collapse-2" class="mt-2">
                    <b-card>
                      <p class="card-text">
                        <img
                          src="@/assets/images/carousel/smarttruck.png"
                          alt=""
                          class="w-100"
                        />
                      </p>
                    </b-card> </b-collapse
                ></b-col>
              </b-row>
              <div class="text-center mt-4">
                <h4>You can download our apps here:</h4>
                <b-link
                  class="ml-25"
                  href="https://play.google.com/store/apps/details?id=com.golog.driverapp"
                  target="_blank"
                >
                  <FeatherIcon icon="PlayIcon" size="15" />Driver App
                </b-link>
                <b-link
                  class="mx-25"
                  href="https://play.google.com/store/apps/details?id=com.golog.minidc_tracker_app"
                  target="_blank"
                >
                  <FeatherIcon icon="MoveIcon" size="15" />Tracker App
                </b-link>
              </div>
            </b-card>
          </div>
        </div>
      </div>

      <div class="p-1 cardColor mt-3">
        <div>
          <img class="img-fluid" src="/1.jpg" alt="First image" />
        </div>
        <div>
          <img class="img-fluid" src="/2.jpg" alt="Second image" />
        </div>
        <div>
          <img class="img-fluid" src="/3.jpg" alt="Third image" />
        </div>
        <div>
          <img class="img-fluid" src="/4.jpg" alt="Fourth image" />
        </div>

        <div class="d-flex justify-content-center">
          <h3
            class="text-primary mx-3 mt-3 customText"
            style="text-align: justify"
          >
            <strong>Customer Portfolio</strong>
          </h3>
        </div>
        <b-card class="mt-2 fade-in-from-bottom rcorners1" ref="customer">
          <div class="d-flex justify-content-center mx-1">
            <img
              src="@/assets/images/carousel/customerportfolio.png"
              alt=""
              class="w-100"
            />
          </div>
        </b-card>
      </div>

      <!-- <div>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <h3 class="text-primary mx-3 customText">
                <strong>Our Achievements</strong>
              </h3>
            </div>
          </div>
        </div>
        <b-carousel id="carousel-1" :interval="3000" controls>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/achievement1.png"
                  alt=""
                  style="
                    object-fit: fit;
                    object-position: center;
                    width: 100%;
                    height: 40vh;
                  "
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h6 class="mx-1 customText mt-2 text-justify">
                  Create@Alibaba Cloud Startup Contest
                </h6>
              </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/achievement2.png"
                  alt=""
                  style="
                    object-fit: fit;
                    object-position: center;
                    width: 100%;
                    height: 40vh;
                  "
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h6 class="mx-1 customText mt-2 text-justify">
                  JCI SUSTAINABLE DEVELOPMENT AWARDS 2022
                </h6>
              </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/achievement3.png"
                  alt=""
                  style="
                    object-fit: fit;
                    object-position: center;
                    width: 100%;
                    height: 40vh;
                  "
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h6 class="mx-1 customText mt-2">
                  GOLOG X MYDIN MOU SIGNING COLLABORATION
                </h6>
              </div>
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="@/assets/images/carousel/achievement4.png"
                  alt=""
                  style="
                    object-fit: fit;
                    object-position: center;
                    width: 100%;
                    height: 40vh;
                  "
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <h6 class="mx-1 customText mt-2">MYDIN DELIVERY ON GOING</h6>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div> -->
      <div class="mt-3" id="outlet">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="text-primary mx-3 customText" style="text-align: justify">
            <strong>Our Outlets</strong>
          </h3>
        </div>

        <b-col class="my-1 p-1">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/hq.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Selangor (HQ)</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >26, Jalan Industri Mas 14, Taman Mas, 47100 Puchong,
              Selangor</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>017-288 1735</b-col>
          </b-row>
        </b-col>
        <b-col class="my-1 p-1"
          ><div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/seremban.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Seremban</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >169G, Jalan S2B3, Seremban 2, 70300 Seremban, Negeri
              Sembilan</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>012-313 4909</b-col>
          </b-row></b-col
        >
        <b-col class="my-1 p-1"
          ><div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/jb_360.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Johor Bahru</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >59, Jalan Ros Merah 2/2, Taman Johor Jaya, 81100 Johor Bahru,
              Johor</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>016-742 7666</b-col>
          </b-row></b-col
        >
        <b-col class="my-1 p-1">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/melaka.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Melaka</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >6, JALAN IAN 3, Jln Industri Angkasa Nuri, 76100 , Melaka</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>011-3534 4593</b-col>
          </b-row>
        </b-col>
        <b-col class="my-1 p-1"
          ><div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/bukit_mertajam.png"
              style="
                object-fit: contain;
                object-position: center;
                width: 29%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Bukit Mertajam</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >9, Jalan Limau Manis 4, Taman Limau Manis, 14000 Bukit Mertajam,
              Pulau Pinang</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>012-439 2996</b-col>
          </b-row></b-col
        >
        <b-col class="my-1 p-1"
          ><div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/taiping.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Taiping</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >No.9, Tingkat Bawah Jalan Susur Simpang, Off Jalan Simpang, Taman
              Perusahaan Ringan Simpang, 34000 Taiping, Perak</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>016-551 1133</b-col>
          </b-row></b-col
        >

        <b-col class="my-1 p-1">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/terengganu.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Kuala Terengganu</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >85-F (Tingkat Bawah), Jalan Sultan Sulaiman, 20000 Kuala
              Terengganu, Terengganu</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>016-959 0060</b-col>
          </b-row>
        </b-col>
        <b-col class="my-1 p-1"
          ><div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/ipoh.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 29%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Ipoh</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >No. 26 Bercham Bistari 1, Medan Bercham Bistari, 31400 Iphoh,
              Perak</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>012-581 1285</b-col>
          </b-row></b-col
        >
        <b-col class="my-1 p-1"
          ><div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/carousel/klang.png"
              style="
                object-fit: fill;
                object-position: center;
                width: 50%;
                height: 50%;
              "
            />
          </div>
          <h5
            class="text-primary mt-2 customText d-flex align-items-center justify-content-center"
          >
            <strong>Klang</strong>
          </h5>
          <b-row class="d-flex align-items-center justify-content-center my-1">
            <b-col cols="1"><FeatherIcon icon="MapPinIcon" size="25" /></b-col>
            <b-col
              >No.7-G, Jalan Kasuarina 4/KS7, Bandar Botanic, 4100 Klang,
              Selangor D.E.</b-col
            >
          </b-row>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="1"><FeatherIcon icon="PhoneIcon" size="25" /></b-col>
            <b-col>011-6272 7662</b-col>
          </b-row></b-col
        >
      </div>
      <div class="mx-1 bordertopmobile" style="font-size: 12px">
        <b-row>
          <b-col cols="8">
            <b-row>
              <b-col cols="2" class="d-flex align-items-center">
                <img
                  :src="require('@/assets/images/logo/logo-red.png')"
                  style="height: 24px"
                />
              </b-col>
              <b-col>
                <div>
                  © {{ new Date().getFullYear() }}
                  <b-link href="" target="_blank">
                    GOBUILDERS NETSOFT SDN BHD (1232479-T).
                  </b-link>
                  ALL RIGHTS RESERVED.
                </div>
                <b-link class="text-primary" href="#">
                  (CONTACT US: 0172881735)
                </b-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4">
            <div>
              <b-link
                @click="
                  openModal(
                    'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true',
                    'Refund Policy'
                  )
                "
              >
                Refund Policy
              </b-link>
              <hr />
              <b-link
                @click="
                  openModal(
                    'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true',
                    'Data Privacy Policy'
                  )
                "
              >
                Data Privacy Policy
              </b-link>
              <hr />
              <b-link
                @click="
                  openModal(
                    'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true',
                    'Rules & Conduct Policy'
                  )
                "
              >
                Rules & Conduct Policy
              </b-link>
              <hr />
              <b-link
                @click="
                  openModal(
                    'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true',
                    'Privacy Terms Policy'
                  )
                "
              >
                Privacy Terms Policy
              </b-link>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-modal
        id="modal-2"
        title="BootstrapVue"
        hide-header
        hide-footer
        class="modal"
        size="lg"
      >
        <div class="d-none d-lg-block">
          <b-row class="my-2">
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <FeatherIcon icon="BoxIcon" size="55" />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <FeatherIcon icon="TruckIcon" size="55" />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <FeatherIcon icon="PackageIcon" size="55" />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <FeatherIcon icon="TruckIcon" size="55" />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <FeatherIcon icon="ShoppingCartIcon" size="55" />
              </div>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  style="font-size: 10.5px"
                  variant="primary"
                  class="btn-sm-block"
                  @click="redirectToExternalSite"
                >
                  Outlet
                </b-button>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  style="font-size: 10.5px"
                  variant="primary"
                  class="btn-sm-block"
                  @click="redirectToExternalB2c"
                >
                  Distributor B2C
                </b-button>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  href="https://b2bpro.golog.my/login"
                  target="_blank"
                  class="btn btn-primary btn-sm-block"
                  style="font-size: 10.5px"
                >
                  B2B Pro
                </a>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  href="https://smart.golog.my/"
                  target="_blank"
                  class="btn btn-primary btn-sm-block"
                  style="font-size: 10.5px"
                >
                  Smart Truck
                </a>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-center">
                <a
                  href="https://www.dhl.com"
                  target="_blank"
                  class="btn btn-primary btn-sm-block"
                  style="font-size: 10.5px"
                >
                  DHL
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="d-block d-lg-none">
          <div class="my-2">
            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="BoxIcon" size="55" />
              <b-button
                style="font-size: 10.5px"
                variant="primary"
                class="btn-sm-block"
                @click="redirectToExternalSite"
              >
                Outlet
              </b-button>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="TruckIcon" size="55" />
              <b-button
                style="font-size: 10.5px"
                variant="primary"
                class="btn-sm-block"
                @click="redirectToExternalB2c"
              >
                Distributor B2C
              </b-button>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="PackageIcon" size="55" />
              <a
                href="https://b2bpro.golog.my/login"
                target="_blank"
                class="btn btn-primary btn-sm-block"
                style="font-size: 10.5px"
              >
                B2B Pro
              </a>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="TruckIcon" size="55" />
              <a
                href="https://smart.golog.my/"
                target="_blank"
                class="btn btn-primary btn-sm-block"
                style="font-size: 10.5px"
              >
                Smart Truck
              </a>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <FeatherIcon icon="ShoppingCartIcon" size="55" />
              <a
                href="https://www.dhl.com"
                target="_blank"
                class="btn btn-primary btn-sm-block"
                style="font-size: 10.5px"
              >
                DHL
              </a>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <b-modal v-model="showModal" :title="modalTitle" size="lg" hide-footer>
      <iframe
        :src="docUrl"
        width="100%"
        height="750px"
        frameborder="0"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      docUrl: "",
      modalTitle: "",
    };
  },
  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.2,
    };
    const titlelogo = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.titlelogo.classList.add("show");
        titlelogo.unobserve(entries[0].target);
      }
    }, options);
    titlelogo.observe(this.$refs.titlelogo);

    const titletext = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.titletext.classList.add("show");
        titletext.unobserve(entries[0].target);
      }
    }, options);
    titletext.observe(this.$refs.titletext);

    const titletext2 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.titletext2.classList.add("show");
        titletext2.unobserve(entries[0].target);
      }
    }, options);
    titletext2.observe(this.$refs.titletext);

    const createorder = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.createorder.classList.add("show");
        createorder.unobserve(entries[0].target);
      }
    }, options);
    createorder.observe(this.$refs.createorder);

    const problem1 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem1.classList.add("show");
        problem1.unobserve(entries[0].target);
      }
    }, options);
    problem1.observe(this.$refs.problem1);
    const problem2 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem2.classList.add("show");
        problem2.unobserve(entries[0].target);
      }
    }, options);
    problem2.observe(this.$refs.problem2);
    const problem3 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.problem3.classList.add("show");
        problem3.unobserve(entries[0].target);
      }
    }, options);
    problem3.observe(this.$refs.problem3);

    const customer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.$refs.customer.classList.add("show");
        customer.unobserve(entries[0].target);
      }
    }, options);
    customer.observe(this.$refs.customer);
  },
  created() {
    if (this.$route.path == "/privacy") {
      this.openPrivacyModal();
    }
  },
  methods: {
    openModal(url, title) {
      this.docUrl = url;
      this.modalTitle = title;
      this.showModal = true;
    },
    redirectToExternalSite() {
      window.location.href = "https://minidc.golog.my";
    },
    redirectToExternalB2c() {
      window.location.href = "https://minidcdistributor.golog.my";
    },
    openPrivacyModal() {
      // Add /privacy to the URL
      this.$router.push("/privacy");
      this.showPrivacyModal = true;
    },
    closePrivacyModal() {
      // Remove /privacy from the URL
      this.$router.replace("/");
    },
  },
};
</script>

<style>
.azalea {
  color: rgb(235, 36, 75);
}

.customText {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.cardColor {
  background-color: rgba(236, 234, 234, 0.911);
}

.rcorners1 {
  border-radius: 90px;
}

.rcorners2 {
  border-radius: 15px 50px;
}

.rcorners3 {
  border-radius: 150px;
}

.bordertopmobile {
  border-top: 1px solid rgb(235, 36, 75);
}

.borderbottommobile {
  border-bottom: 1px solid rgb(235, 36, 75);
}

.borderrightmobile {
  border-right: 1px solid rgb(235, 36, 75);
}

.borderleftmobile {
  border-left: 1px solid #db1d6c;
}

.borderxmobile {
  border-left: 1px solid #db1d6c;
  border-right: 1px solid #db1d6c;
}

.animated-link {
  text-decoration: none;
  color: rgb(235, 36, 75);
  position: relative;
  transition: all 0.3s ease-in-out;
}

.animated-link::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #db1d6c;
  transition: all 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.animated-link:hover::before {
  transform: scaleX(1);
}

.fade-in-from-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-right {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-from-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-left {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-from-bottom {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease-in, transform 1.5s ease-in;
}

.show.fade-in-from-bottom {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-pop {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1.5s ease-in,
    transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.show.fade-in-pop {
  opacity: 1;
  transform: scale(1);
}

.fade-in-from-bottom1 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s ease-in, transform 2s ease-in;
}

.show.fade-in-from-bottom1 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottom2 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2.3s ease-in, transform 2.3s ease-in;
}

.show.fade-in-from-bottom2 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottom3 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2.6s ease-in, transform 2.6s ease-in;
}

.show.fade-in-from-bottom3 {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-from-bottomprogress {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 3s ease-in, transform 3s ease-in;
}

.show.fade-in-from-bottomprogress {
  opacity: 1;
  transform: translateY(0);
}

.modal {
  top: 30%;
}
</style>